import React from 'react';
import Button from '@components/base/button';
import ConstructorElement, { ConstructorElementInterfaceP, ConstructorElementInterfaceS, LogInterface, requestInterface } from '@abstract/constructorElement';
import $order from '@effector/order';
import parseString from '@helpers/parseString';
import ModalAlert, { AlertData } from '@components/base/alert';
import dispatchEvent from '@helpers/dispatchEvent';
import { ControllerInterface } from '@abstract/modal';
import setClasses from '@helpers/setClasses';
import splitCity from '@helpers/splitCity';
import ModalEmail from './shop/modalEmail';
import Input from '@components/base/input';
import $global, { updateGlobal } from '@effector/global';
import * as yup from 'yup';
import { setPreloader } from '@effector/preloader';
import SubmitAlert, { AlertData as SubmitAlertData } from '@components/base/submitAlert';
import $cache, {updateCache} from "@effector/cache";

interface NotExistRequiredFieldsInterface {
  name: string
  value: string
  placeholder?: string
  validateMethod: string
  request: requestInterface
}
export interface LogsInterface {
  mount: LogInterface
  close: LogInterface
  go2site: LogInterface
}
export interface P extends ConstructorElementInterfaceP {
  type: 'submit'
  title?: any
  onSubmit: string
  isAlertText: boolean
  submitAnswer: any
  validation: any
  logs: LogsInterface
  boxSettings: {
    title?: string
    description?: string
    timeout?: number
  }
  preRequest: requestInterface
  request: requestInterface
  notExistRequiredFields: NotExistRequiredFieldsInterface[]
  successAlert: SubmitAlertData
}

interface S extends ConstructorElementInterfaceS {
  email: string
  alertText: string
  statusText: string | null
}
export class Submit extends ConstructorElement<P, S> {
  alert?: ControllerInterface<AlertData>
  email?: ControllerInterface<AlertData>
  submitAlert?: ControllerInterface<SubmitAlertData>

  constructor(props: P) {
    super(props)
    this.state = {
      ...this.state,
      email: '',
      alertText: '',
      statusText: null
    } as S
  }


  async validateEmail(str: string): Promise<[boolean, string]> {
    return new Promise(async (resolve) => {
      let result: [boolean, string] = [false, 'Заполните поле email']
      if (str) {
        let schema = yup.object().shape({
          email: yup.string().email(),
        });

        await schema
          .isValid({ email: str })
          .then((valid) => {
            if (valid) {
              result = [true, '']
            }
            else {
              result = [false, 'Вы указали некорректный email']
            }
            return valid
          });
      }

      resolve(result);
    })
  }

  async validateDefault(str: string, name: string): Promise<[boolean, string]> {
    return new Promise(async (resolve) => {
      let result: [boolean, string] = [true, '']

      if (!str || !str.length) result = [false, `Заполните поле ${name}`]

      resolve(result);
    })
  }

  createBody = (config: any) => {
    const values = { ...this.state.observeValues }
    let body: any = {};

    config?.forEach(({ name, value, transform, type }: any) => {
      let _name: string = this.parseValue(name, values, {}, true)[0] || name;

      let [_value] = this.parseValue(value, values, {}, true);
      body[_name] = parseString(this.transform(_value, transform), type);
    })

    return body;
  }

  parseValuesInObj(data: any): any {
    let _data: any = { ...{}, ...data };

    Object.keys(_data).forEach((key: string) => {
      const value: any = _data[key]
      if (typeof value === 'string') {
        _data[key] = this.parseValue(value, this.state.observeValues, false, true)[0]
      }
      else if (Array.isArray(value)) {
        _data[key] = value.map((__data: any) => this.parseValuesInObj(__data))
      }
      else {
        _data[key] = this.parseValuesInObj(value)
      }
    })

    return _data;
  }

  deepDiff = ({ currentBody, changedOrder }: { currentBody: any[], changedOrder: any[] }): any[] => {
    let result: any[] = [];
    changedOrder.forEach((item, key) => {
      let diff: any = {};
      Object.keys(item).forEach(name => {
        if (name !== 'user' && name !== 'product' && (!currentBody[key][name] || item[name] !== currentBody[key][name])) {
          diff[name] = item[name];
        }
      });
      if (diff) {
        result[key] = diff;
      }
    });
    return result;
  }

  diffBasket = ({ currentBody, changedOrder }: { currentBody: any, changedOrder: any }): Promise<any> => {
    return new Promise((resolve, reject) => {
      const diff = this.deepDiff({ currentBody, changedOrder });
      if (diff.length) {
        reject(diff)
      }
      else resolve(diff)
    })
  }

  requestFields = async (apply: Function) => {
    const that: any = this;
    const { notExistRequiredFields } = this.props;
    let inputs: NotExistRequiredFieldsInterface[] = []
    await Promise.all(notExistRequiredFields.map(async (item, key) => {
      return new Promise(async (resolve) => {
        let status: boolean = true;
        const parsedValue = parseString(this.parseValue(item.value, this.state.observeValues)[0])
        if (item.validateMethod && that[item.validateMethod]) {
          let [_status]: [boolean, string] = await that[item.validateMethod](parsedValue)
          status = _status;
        }
        else {
          if (!parsedValue) {
            status = false
          }
        }
        if (!status) {
          inputs[key] = (item)
        }
        resolve(status)
      })
    }))

    if (inputs.length) {
      this.email?.open({
        title: 'Заполните поля',
        item: {
          title: inputs.map((input, key) => (
            <Input
              className="mt-4"
              placeholder={input.placeholder}
              key={key}
              onChange={(value) => {
                const _state: any = { [input.name]: value };
                this.setState(_state)
              }}
            />
          ))
        },
        controlsSettings: {
          title: (
            <Button
              isActive
              title="Подтвердить"
              onClick={async () => {
                if (notExistRequiredFields) {
                  Promise.all(notExistRequiredFields.map(async (item) => {
                    return new Promise<{ status: boolean, message?: string | undefined, data?: any, request?: requestInterface }>(async (resolve) => {
                      const state: any = this.state;
                      if (state[item.name]) {
                        updateGlobal({ [item.name]: state[item.name] })
                        const cache = $cache.getState();

                        if ([item.name][0] === 'name') {
                          if (cache.user) {
                            cache.user.name = state[item.name]
                            updateCache(cache)
                          }
                        }

                        if ([item.name][0] === 'email') {
                          if (cache.user) {
                            cache.user.email = state[item.name]
                            updateCache(cache)
                          }
                        }
                      }
                      let status: boolean = true, message: string = '';
                      const parsedValue = parseString(this.parseValue(item.value, this.state.observeValues)[0])
                      if (item.validateMethod && that[item.validateMethod]) {
                        let [_status, _message]: [boolean, string] = await that[item.validateMethod](parsedValue)
                        status = _status;
                        message = _message;
                      }
                      else {
                        let [_status, _message]: [boolean, string] = await this.validateDefault(parsedValue, item.name);
                        status = _status;
                        message = _message;
                      }

                      if (!status) {
                        resolve({ status, message })
                      }
                      else {
                        resolve({ status: false, request: item.request })
                      }
                    })
                  })).then((fields) => {
                    let staticErrors = fields.filter((item) => !item.status && !item.request)
                    if (staticErrors.length) {
                      const [error] = staticErrors;
                      window.box?.open({ title: error.message, status: 'error' })
                    }
                    else {
                      let needRequests = fields.filter((item) => !item.status && item.request);
                      if (needRequests.length) setPreloader(true)
                      Promise.all(needRequests.map((item) => {
                        return new Promise<{ status: boolean, message?: string | undefined, data?: any, request?: requestInterface }>((resolve) => {
                          this.request({ ...item.request, abortController: new AbortController() }, (data: any) => {
                            resolve({ status: true, data })
                          }, (err: any) => {
                            resolve({ status: false, message: err.error ? err.error[0] : undefined })
                          })
                        })
                      })).then((items) => {
                        setPreloader(false)
                        let staticErrors = items.filter((item) => !item.status)
                        if (staticErrors.length) {
                          const [error] = staticErrors;
                          window.box?.open({ title: error.message, status: 'error' })
                        }
                        else {
                          apply()
                        }
                      })
                    }
                  })
                }
                else {
                  apply()
                }
              }}
              className="rounded-md py-3"
              touchableOpacityClassName="w-full"
              wrapperClassName="flex-1"
            />
          )
        }
      })
    }
    else {
      apply()
    }
  }

  submitMethods: any = {
    shop: async () => {
      setPreloader(true)
      const { request, submitAnswer } = this.props;
      let body = this.parseValuesInObj(request).body;
      const bodySubmitAnswer = this.createBody(submitAnswer);

      //Проверяем, есть ли в корзине уже пробный рацион
      if('trial' === bodySubmitAnswer.type) {
        let basket = body.find((item: any) => item.name === 'basket');
        if (basket) {
          basket = JSON.parse(basket.value);

          let trialCount = 0;
          basket.forEach((item: any, i: number) => {
            if('trial' === item.type) {
              trialCount++;
            }
          });

          if(trialCount > 0) {
            setPreloader(false);
            window.box?.open({ title: 'В корзину можно добавить только один пробный рацион', status: 'error' });
            return;
          }
        }
      }

      const list: any = [...[], ...$order.getState()];
      list.push(bodySubmitAnswer);
      const city = splitCity(this.state.observeValues.address.title);
      await window.orderStore.add(city, list);
      body = this.parseValuesInObj(request).body;

      this.request({ ...request, body }, async (res: any) => {
        let basket = body.find((item: any) => item.name === 'basket');

        if (basket) {
          basket = JSON.parse(basket.value)
        }

        const bodyFormatted = this.createBody(body);
        this.diffBasket({
          currentBody: bodyFormatted.basket,
          changedOrder: res.basket,
        })
          .then(() => {
            return this.props.getProps().history.push('/shop')
          })
          .catch(async (diff) => {
            list.forEach((item: any, i: number) => {
              if (!item.time && diff[i] !== undefined) {
                item.time = res.time
              }

              item.date = res.date
            })

            await window.orderStore.replace(city, list);
            dispatchEvent('updatePrice');
            this.props.getProps().history.push('/shop');

            return diff;
          })
          .then(() => {
            setPreloader(false)
          })
      }, (err: any) => {
        setPreloader(false)
        window.box?.open({ title: err.message, status: 'error' })
      })
    },
    order: async () => {
      const { request, preRequest } = this.props;
      this.setState({ error: undefined })

      const afterPreRequest = () => {
        setPreloader(true)
        this.request(request, (res: any) => {
          setPreloader(false)
          const { successAlert } = this.props;
          const _successAlert = this.parseValuesInObj(successAlert);
          let settings = {
            ..._successAlert,
            order: `№${res.id}`,
            status: res.status,
            onClose: () => {
              window.orderStore.clear()
            }
          };
          if (_successAlert.button) {
            settings['button'] = {
              ..._successAlert.button, onClick: () => {
                this.log(this.props.logs.go2site)
              }, url: res.url
            }
          }

          this.submitAlert?.open(settings)
        }, (err: any) => {
          window.box?.open({ title: err.message, status: 'error' })
          setPreloader(false)
        })
      }

      this.requestFields(() => {
        this.email?.close()
        setPreloader(true)
        this.request(preRequest, async (res: { basket: any, fias: string }) => {
          const body: { basket: any[] } = this.createBody(preRequest.body);

          this.diffBasket({
            currentBody: body.basket,
            changedOrder: res.basket,
          })
            .then(() => {
              afterPreRequest()
              return
            })
            .catch(async (diff) => {
              const arrDeletedKeys = ['user', 'product', 'counts', 'isExtra', 'date', 'time', 'sumMax', 'productInfo', 'combo', 'onlyDisplay', 'isSet'];

              // удаление лишних полей из разницы
              diff.forEach((item: any, i: number) => {
                Object.keys(item).forEach((key: any,  index: number) => {
                  if (arrDeletedKeys.indexOf(key) !== -1) {
                    delete item[key];
                  }
                })
              });

              diff.forEach((item: any, i: number) => {
                if (!Object.keys(item).length) {
                  delete diff[i];
                }
              });

              setPreloader(false)
              const { boxSettings } = this.props;
              if (!Object.keys(diff).length) {
                afterPreRequest()
                return
              }

              diff.forEach((item: {} | false, key: number) => {
                if (item) {
                  try {
                    window.CE
                      .get('shop')
                      .externalUpdate({ item, key })
                      .showExternalChanged({ item, key })
                  } catch (error: any) {
                  }
                }
              })

              window.box?.open({
                title: boxSettings.title,
                description: boxSettings.description,
                status: 'info',
                timeout: boxSettings.timeout || 3000
              })

              console.log(diff)
              return diff
            })
        }, (err: { message: string }) => {
          console.log(err)
          window.box?.open({ title: err.message, status: 'error' })
          setPreloader(false)
        })
      })
    },
    coupon: async () => {
      const { request } = this.props;

      if (request.url.indexOf('ability') === -1) {
        return
      }

      this.setState({
        error: undefined,
        alertText: '',
        statusText: null
      });

      if ($global.getState().coupon === '' || $global.getState().coupon === undefined) {
        dispatchEvent('updateOrder');
        return
      }

      this.requestFields(() => {
        this.email?.close();
        setPreloader(true);

        this.request(request, async (res: { basket: any, fias: string, couponText: string }) => {
          const body: { basket: any[] } = this.createBody(request.body);

          this.diffBasket({
            currentBody: body.basket,
            changedOrder: res.basket,
          })
            .then(() => {
              this.setState({
                alertText: res.couponText === '' ? 'Промокод применен' : res.couponText,
                statusText: 'success'
              })
              return dispatchEvent('updatePrice');
            })
            .catch(async (diff) => {
              const city = splitCity(this.state.observeValues.address.title);
              const list: any = [...[], ...$order.getState()];

              if (diff && diff.length) {
                diff.forEach((item: any, i: number) => {
                  Object.keys(item).forEach((key: any,  index: number) => {
                    if (key === 'user' || key === 'product') {
                      delete item[key];
                    }
                  })
                });

                diff.forEach((item: any, i: number) => {
                  if (!Object.keys(item).length) {
                    delete diff[i];
                  }
                });
              }

              setPreloader(false)
              list.forEach((item: any, i: number) => {
                if (diff[i] !== undefined) {
                  item.price = diff[i].price
                  item.sum = diff[i].sum
                }
              })

              await window.orderStore.replace(city, list);
              this.setState({
                alertText: res.couponText === '' ? 'Промокод применен' : res.couponText,
                statusText: 'success'
              })
              dispatchEvent('updatePrice');

              if (diff && diff.length) {
                diff.forEach((item: {} | false, key: number) => {
                  if (item) {
                    try {
                      window.CE
                        .get('shop')
                        .externalUpdate({ item, key })
                        .showExternalChanged({ item, key })
                    } catch (error: any) {
                    }
                  }
                })
                return diff
              }
            })
        }, (err: { message: string }) => {
          this.setState({
            alertText: err.message,
            statusText: 'error'
          })
          dispatchEvent('updateOrder');
          setPreloader(false)
        })
      })
    }
  }

  requestCatch(error: any) {
    window.box?.open({ title: error.message, status: 'error' })
  }

  request = (request: any, cb: Function, cbError?: Function) => {
    if (request?.body) {
      const body = this.createBody(request?.body);

      this._getData((res: any) => { }, {
        ...request,
        body: Object.keys(body).map((name: string) => ({ name, value: body[name] }))
      }, body, (res: any) => {
        cb(res)
      }, (err: any) => {
        if (cbError)
          cbError(err)
      })
    }
  }

  isValide = () => {
    let isValide = true
    let body = this.createBody(this.props.validation)
    this.props.validation.forEach(({ name, value, type, pathInArray }: any) => {
      if (pathInArray && value) {
        let [array] = this.parseValue(value, {}, {}, true);
        array = parseString(array, type);
        array.forEach((_value: any) => {
          if (!_value[pathInArray]) {
            isValide = false
          }
        })
      }
      else {
        if (body[name] === "null" || body[name] === null) {
          isValide = false
        }
      }
    })

    return isValide
  }

  onSubmit = () => {
    const { onSubmit } = this.props;
    if (this.isValide()) {
      this.log()
      this.submitMethods[onSubmit]()
    } else {
      this.validationAlert();
    }
  }

  validationAlert = () => {
    const body = this.createBody(this.props.validation)
    const fields = this.props.validation.filter((item: any) => item.invalidAlert)

    if (body.payment === null) {
      window.box?.open({ title: 'Выберите способ оплаты!', status: 'error' });
    }

    if (fields.length) {
      fields.forEach((field: any) => {
        if (body[field.name] === "null" || body[field.name] === null) {
          window.CE.get(field.name).showExternalChanged()
        }
      })
    }
  }

  componentDidMount() {
    super.componentDidMount()
    document.addEventListener('triggerCoupon', this.triggerCoupon)
    document.addEventListener('clearCouponText', this.clearCouponText)
  }

  componentWillUnmount() {
    super.componentWillUnmount()
    document.removeEventListener('triggerCoupon', this.triggerCoupon)
    document.removeEventListener('clearCouponText', this.clearCouponText)
  }

  triggerCoupon = () => {
    this.submitMethods['coupon']()
  }

  clearCouponText = () => {
    this.setState({
      alertText: '',
      statusText: null
    })
  }

  /* disabling get data from didMount */
  getData = async () => { }
  /* disabling get data from didMount */

  public _render(children: any): JSX.Element {
    return (
      <div className={setClasses([], {
        hidden: !this.checkConditions(),
      })}>
        {children}
        <ModalAlert
          controller={controller => this.alert = controller}
        />
        <ModalEmail
          controller={email => this.email = email}
        />
        <SubmitAlert controller={submitAlert => this.submitAlert = submitAlert} />
      </div>
    )
  }

  public render(): JSX.Element {
    const { title, isAlertText } = this.props;
    const { alertText, statusText } = this.state

    return (
      this._render(
        <div className="px-4">
          <Button
            onClick={this.onSubmit}
            title={title}
            isActive
            touchableOpacityClassName="w-full"
            className="rounded-10px py-4"
            wrapperClassName="mt-4"
          />
          {isAlertText && alertText &&
            <div className={setClasses(['mt-4 text-center'], {
              'text-error': statusText === 'error',
              'text-primary': statusText === 'success'
            })}>
              {alertText}
            </div>
          }
        </div>
      )
    )
  }
}

export default Submit;
